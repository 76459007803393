export interface Field {
	label: string;
	property: string;
}

export interface Section {
	title: string;
	fields: Field[];
}

export const DETAIL_STRUCTURE: Section[] = [
	{
		title: 'Category information',
		fields: [
			{ label: 'Category 1', property: 'cat1NL' },
			{ label: 'Category 2', property: 'cat2NL' },
			{ label: 'Category 3', property: 'cat3NL' }
		]
	},
	{
		title: 'Cooling information',
		fields: [
			{ label: 'Refrigerant', property: 'refrigerant' },
			{ label: 'Operating temperature range', property: 'operatingTemperatureRange' },
			{ label: 'Ambient temperature range', property: 'ambientTemperatureRange' },
			{ label: 'Climate class', property: 'climateClass' }
		]
	},
	{
		title: 'Dimension and volume information',
		fields: [
			{ label: 'External dimensions', property: 'externalDimensions' },
			{ label: 'Internal dimensions', property: 'internalDimensions' },
			{ label: 'Gross volume', property: 'grossVolume' },
			{ label: 'Net volume', property: 'netVolume' }
		]
	},
	{
		title: 'Electrical information',
		fields: [
			{ label: 'Nominal voltage/frequency', property: 'nominalVoltageAndFrequency' },
			{ label: 'Fuse protection', property: 'fuseProtection' },
			{ label: 'Power plug', property: 'powerPlug' },
			{ label: 'Nominal power consumption', property: 'nominalPowerConsumtion' },
			{ label: 'Power consumption illumunation', property: 'powerConsumtionIlumination' },
			{ label: 'Power consumption defrostingillumunation', property: 'powerConsumtionDefrostingIlumination' },
			{ label: 'Energy consumption', property: 'energyConsumtion' },
			{ label: 'Energy class', property: 'energyClass' }
		]
	},
	{
		title: 'Facing, basket and interieur information',
		fields: [
			{ label: 'Number of facings', property: 'numberOfFacings' },
			{ label: 'Number of baskets', property: 'numberOfBaskets' },
			{ label: 'Interieur information', property: 'interieurInformation' }
		]
	},
	{
		title: 'Financial information',
		fields: [
			{ label: 'Asset number', property: 'assetNumber' },
			{ label: 'Purchase order', property: 'purchaseOrder' },
			{ label: 'Purchasing value', property: 'purchasingValue' },
			{ label: 'I/O of the books', property: 'financialAdministration' },
			{ label: 'Start depreciation', property: 'startDeprecation' },
			{ label: 'Depreciation period', property: 'deprecationPeriod' },
		]
	},
	{
		title: 'Logistical information',
		fields: [
			{ label: 'Dimensions incl. packaging', property: 'dimensionsIncludingPackaging' },
			{ label: 'Gross weight', property: 'grossWeight' },
			{ label: 'Net Weight', property: 'netWeight' },
			{ label: 'Number per 20ft container', property: 'number20ftContainer' },
			{ label: 'Number per 40ft container', property: 'number40ftContainer' },
			{ label: 'Number per standard trailer', property: 'numberStandardTrailer' },
			{ label: 'Number per mega trailer', property: 'numberMegaTrailer' },
			{ label: 'Costs transport warehouse to POS', property: 'costTrasportWarehouseToPos' },
			{ label: 'Costs transport POS to warehouse', property: 'costTransportPosToWarehouse' },
			{ label: 'Max. stacking height in warehouse', property: 'maxStackingHeightInWarehouse' }
		]
	},
	{
		title: 'Manufacturer/suplier information',
		fields: [
			{ label: 'Manufacturer', property: 'manufacturer' },
			{ label: 'Website manufacturer', property: 'websiteManufacturer' },
			{ label: 'Supplier', property: 'supplier' },
			{ label: 'Website supplier', property: 'websiteSupplier' },
            { label: 'Serial number', property: 'serialNumber' }
		]
	},
	{
		title: 'Option and accessorie information',
		fields: [
			{ label: 'Illumination', property: 'illumination' },
			{ label: 'Lock and key', property: 'lockAndKey' },
			{ label: 'Frame', property: 'frame' },
			{ label: 'Other', property: 'optionOther' }
		]
	},
	{
		title: 'Repair & Maintenance information',
		fields: [
			{ label: 'Repair & Maintenance preformed at POS', property: 'remAtPos' },
			{ label: 'Spare parts provided by', property: 'remSpareParts' }
		]
	},
	{
		title: 'Sales information',
		fields: [
			{ label: 'Turnover 1st placement', property: 'turnoverFirstPlacement' },
			{ label: 'Turnover 2th placement', property: 'turnoverSecondPlacement' },
			{ label: 'Insurance value', property: 'insuranceValue' }
		]
	}/* ,
	{
		title: 'Notes',
		fields: [
			{ label: 'Notes', property: 'notes' }
		]
	} */
];