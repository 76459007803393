import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	private titleSubject: Subject<any>;

	constructor() {
		this.titleSubject = new Subject();
	}

	onChangeTitle() {
		return this.titleSubject;
	}

}