import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { HttpRequest, HttpHandler } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class Interceptor {

	constructor(private auth: AuthService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		request = request.clone({
			headers: request.headers.append('Authorization', 'Bearer ' + (this.auth.getToken() || ''))
		});

		return next.handle(request).pipe(tap(null, error => {
			if (error.status === 401) this.auth.logOut();
		}));
	}

}
