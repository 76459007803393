import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { NzModalService, NzModalRef } from 'ng-zorro-antd';

import { ActivaService } from 'src/app/modules/dashboard/services/activa.service';

@Component({
	selector: 'app-upload-document',
	templateUrl: './upload-document.component.html',
	styleUrls: ['./upload-document.component.sass']
})
export class UploadDocumentComponent {

	// Inputs
	public activaNumber: string;
    public typeNumber: string;
	public callback: Function;
	public activaFile: string;

	public form: FormGroup;
	public setFile: Function;
	public file: any;
	public uploading: boolean;

	constructor(
		private activaService: ActivaService,
		private modalService: NzModalService,
		private modal: NzModalRef,
		private fb: FormBuilder
	) {
		this.form = this.fb.group({
			fileName: "",
			// constructionYear: [],
			// specification: [],
			// note: []
		});
		this.setFile = (event: any) => {
			this.file = event;
			return false;
		}
	}

	uploadFile() {
		if (!this.file) return;
		this.uploading = true;
		let fileData: FormData = new FormData();
		let formData: any = this.form.value;
		fileData.append('Document', this.file);
		fileData.append('Id', '0');
		fileData.append('Filename', formData.fileName);
        if(this.activaFile == 'logo'){
            fileData.append('Description', 'HARDWARE_LOGO');
        }else{
            fileData.append('Description', this.activaFile);
            if(this.activaNumber != null){
                fileData.append('ActivaNumber', this.activaNumber);
            }
        }
        fileData.append('TypeNumber', this.typeNumber);
		this.activaService.uploadActivaFile(fileData).subscribe(() => {
			this.callback();
			this.modal.close();
		}, (error: any) => {
			if (error.status) {
				this.modalService.error({ nzTitle: 'Error', nzContent: 'The file couldn\'t be uploaded because its format is not allowed.' });
			}
			this.uploading = false;
		});
	}

}