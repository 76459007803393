import { Component, AfterViewInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NzModalService } from 'ng-zorro-antd';

import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { ActivaService } from '../../services/activa.service';
import { UploadDocumentComponent } from 'src/app/shared/components/upload-document/upload-document.component';
import { DETAIL_STRUCTURE, Section } from './detail-structure';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
	selector: 'app-activa-detail',
	templateUrl: './activa-detail.component.html',
	styleUrls: ['./activa-detail.component.sass']
})
export class ActivaDetailComponent implements AfterViewInit {

	@ViewChild('header', { static: false }) public header: TemplateRef<any>;
	@ViewChild('hardwareLogo', { static: false }) public hardwareLogo: ElementRef<HTMLImageElement>;

	public fromActiva: boolean;
	public activaFromRoute:string;
	public activa: any;
	public hardware: any;
	public detail: any;
	public detailStructure: Section[];
	public savingNotes: boolean;
	public tabsText: string[];
	public selectedTabName: string;
	public files: {
		spareParts: any[],
		artwork: any[],
		images: any[],
		brochuresManuals: any[]
  };

  disableAdminCommands: boolean = true;

	constructor(
		private dashboardService: DashboardService,
		private activaService: ActivaService,
		private route: ActivatedRoute,
		private modalService: NzModalService
	) {
		this.route.url.subscribe(params => {
			this.activaFromRoute = params[0].path;
		  });

		  this.files = {
			spareParts: null,
			artwork: null,
			images: null,
			brochuresManuals: null
		}
		this.detailStructure = DETAIL_STRUCTURE;
		this.detail={};
		this.tabsText = ['spareParts', 'artwork', 'images', 'brochuresManuals'];
		this.selectedTabName = this.tabsText[0];
		this.activa = this.activaService.getActiva();
		this.hardware = this.activaService.getHardware();
		this.detail = this.activaService.getGeneralInfoObject();
		/*if (!this.activa || !this.hardware || !this.detail) {
			this.goBack();
			return;
		}*/
		//debugger;
		if (!this.activa || !this.detail) {
			this.loadDataViewer();
		}else{
			this.initValues();
		}
	}

	ngAfterViewInit() {
		this.dashboardService.onChangeTitle().next(this.header);
		
		
	}

  initValues() {
    this.getDisableAdminCommands();
			if (!this.activa || !this.detail){
				this.goBack();
				return;
			}
		this.detail.cat1NL = this.activa.cat1NL;
		this.detail.cat2NL = this.activa.cat2NL;
		this.detail.cat3NL = this.activa.cat3NL;
		this.detail.assetNumber = this.activa.assetNumber;
		this.detail.purchaseOrder = this.activa.purchaseOrder;
		this.detail.purchasingValue = this.activa.purchasingValue;
		this.detail.financialAdministration = this.activa.financialAdministration;
		this.detail.startDeprecation = this.activa.startDeprecation;
		this.detail.deprecationPeriod = this.activa.deprecationPeriod;

		this.route.data.subscribe((data: any) => this.fromActiva = data.fromActiva);
	
		this.getFiles();
		this.getLogo();
	}

	loadDataViewer(){

		var activa = this.activaFromRoute;

		//debugger;
		forkJoin(
			this.activaService.getActivaByActivaNumber(activa).pipe(catchError(() => of('Activa'))),
			this.activaService.getGeneralInfo(activa).pipe(catchError(() => of('General information')))
		).subscribe((data: any[]) => {
			let errorText: string;
			if (data.every((element: any) => {
				if (typeof (element) == 'string') {
					errorText = element;
					return false;
				}
				return true;
			})) {
				this.activaService.setActiva(data[0]);
				//this.activaService.setHardware(data[1]);
				this.activaService.setGeneralInfo(data[1]);

					this.activa = this.activaService.getActiva();
					this.hardware = this.activaService.getHardware();
					this.detail = this.activaService.getGeneralInfoObject();
					this.initValues();

			} else {
				 this.modalService.info({
				 	nzTitle: 'Error',
				 	nzContent: `There is no ${errorText} related to the selected ${'Activa'}.`
				 });
				this.activaService.cleanData();
				this.goBack();
			}
		});
	}

	goBack() {
		window.history.back();
	}

  getDisableAdminCommands() {
    this.disableAdminCommands=!this.activaService.isInRole("Admin");
	}

	saveNotes() {
		this.savingNotes = true;
		this.activaService.setGeneralInformation(this.detail.id, this.detail).subscribe((newDetail: any) => {
			this.detail.notes = newDetail.notes;
			this.savingNotes = false;
		});
	}

	getFiles() {
		this.tabsText.forEach((text: string) => this.files[text] = []);
		let pointerArray: any[];
        if(this.fromActiva){
            this.activaService.getActivaFiles(this.activa.activaNumber, this.activa.typeNumber).subscribe((files: any[]) => {
				files.forEach((file: any) => {
					pointerArray = this.files[file.description];
					if (!pointerArray) pointerArray = this.files['spareParts'];
					pointerArray.push(file)
				});
            });
        } else {
            this.activaService.getHardwareFiles(this.activa.typeNumber).subscribe((files: any[]) => {
                files.forEach((file: any) => {
					pointerArray = this.files[file.description];
					if (!pointerArray) pointerArray = this.files['spareParts'];
					pointerArray.push(file)
				});
            });
        }
	}

	getLogo() {
		this.tabsText.forEach((text: string) => this.files[text] = []);
		this.activaService.getActivaLogo(this.activa.typeNumber).subscribe((result: any) => {
			this.hardwareLogo.nativeElement.src = result.Response;
		});
	}

	uploadLogo() {
		if(this.disableAdminCommands) return;
		this.modalService.create({
			nzTitle: '<span class="main-title">Hardware Image</span><br><small>Upload a new image</small>',
			nzContent: UploadDocumentComponent,
			nzComponentParams: {
				typeNumber: this.activa.typeNumber,
                activaNumber: this.activa.activaNumber,
				callback: this.getLogo.bind(this),
				activaFile: 'logo'
			},
			nzBodyStyle: { 'max-width': '820px' },
			nzFooter: null
		});
	}

	uploadDocument() {
		this.modalService.create({
			nzTitle: '<span class="main-title">Specification</span><br><small>Upload a new document</small>',
			nzContent: UploadDocumentComponent,
			nzComponentParams: {
                typeNumber: this.activa.typeNumber,
				activaNumber: this.fromActiva ? this.activa.activaNumber : null,
				callback: this.getFiles.bind(this),
				activaFile: this.selectedTabName
			},
			nzBodyStyle: { 'max-width': '820px' },
			nzFooter: null
		});
	}

	downloadFile(file: any) {
		this.activaService.downloadActivaFile(file.id);
	}

	deleteFile(file: any) {
		this.modalService.confirm({
			nzTitle: 'Delete',
			nzContent: `Do you want to delete the <strong>${file.filename}</strong> file?`,
			nzOnOk: () => new Promise((resolve: Function) => {
				this.activaService.deleteFile(file.id).subscribe((res) => {
					resolve();
					this.getFiles();
				});
			})
		});
	}

}
