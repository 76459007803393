import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, Observer } from 'rxjs';

import { AuthService } from '../../authentication/services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class DashboardGuardService implements CanActivate {

	constructor(private auth: AuthService) { }

	canActivate(): Observable<boolean> {
		return Observable.create((obs: Observer<any>) => {
			this.auth.getUser().subscribe((user: any) => {
				if (user) {
					obs.next(true);
					obs.complete();
				} else {
					this.auth.logOut();
					obs.next(false);
					obs.complete();
				}
			});
		});
	}

}
