import { Component } from '@angular/core';

@Component({
	selector: 'app-no-data-table',
	templateUrl: './no-data-table.component.html'
})
export class NoDataTableComponent {

	constructor() { }

}