import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import { NoDataTableComponent } from './components/no-data-table/no-data-table.component';
import { ActivaDetailComponent } from '../modules/dashboard/components/activa-detail/activa-detail.component';
import { UploadDocumentComponent } from './components/upload-document/upload-document.component';

@NgModule({
	declarations: [
		NoDataTableComponent,
		ActivaDetailComponent,
		UploadDocumentComponent
	],
	entryComponents: [
		UploadDocumentComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NzInputModule,
		NzButtonModule,
		NzIconModule,
		NzGridModule,
		NzSelectModule,
		NzTableModule,
		NzToolTipModule,
		NzCollapseModule,
		NzTabsModule,
		NzModalModule,
		NzUploadModule
	],
	exports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		NzInputModule,
		NzButtonModule,
		NzMenuModule,
		NzIconModule,
		NzGridModule,
		NzSelectModule,
		NzTableModule,
		NzToolTipModule,
		NzAlertModule,
		NzUploadModule,
		NzDropDownModule,
		NoDataTableComponent,
		ActivaDetailComponent
	]
})
export class SharedModule { }