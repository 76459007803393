import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReplaySubject, forkJoin, of } from 'rxjs';
import { ActivaService } from '../../dashboard/services/activa.service';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private user: any;
	private userSubject: ReplaySubject<any>;
	private tokenName: string;
	private userId: string;
	private userRoles: string;
	private userName:string;

	constructor(
		private http: HttpClient,
		private router: Router
	) {
		this.tokenName = 'ul-tk';
		this.userId = 'ul-userId';
		this.userRoles = 'ul-userRole';
		this.userName = 'ul-userName';
	}

	logIn(credentials: any) {
		return this.http.post(`${environment.URL_API}/user/authenticate`, credentials);
	}

	logInByCode(code: string):Promise<any> {
        return new Promise((resolve, reject)=>{
			this.http.get(`${environment.URL_API}/user/authenticate/${code}`).subscribe(
				(userData: any) => {
					this.saveAuthData(userData);
					if(userData.activa==null){
						reject();
					}
					//debugger;
					resolve({activa:userData.activa, byHardware:userData.byHardware});
					//this.router.navigate(['search-for/activa', userData.activa ]);
				}, (error: any) => {
					this.router.navigate(['']);
				});
			});
    }

	getUserName(){
		return this.getItem(this.userName);
	}
	
	getItem(item: string) {
		return localStorage.getItem(item);
	}
	
	setItem(item: string, content: string) {
		localStorage.setItem(item, content);
	}

	saveAuthData(authData: any) {
		this.setItem(this.tokenName, authData.token);
		this.setItem(this.userId, authData.id);
		this.setItem(this.userRoles, authData.roles);
		this.setItem(this.userName, authData.firstName);
	}

	getToken() {
		return this.getItem(this.tokenName);
	}

	getUser() {
		if (this.userSubject) return this.userSubject;

		this.userSubject = new ReplaySubject();

		if (this.getItem(this.tokenName) && this.getItem(this.userId)) {
			this.http.get(`${environment.URL_API}/user/${this.getItem(this.userId)}`).subscribe((user: any) => {
				this.user = user;
				this.userSubject.next(this.user);
			});
		} else {
			this.userSubject.next(null);
		}

		return this.userSubject;
	}

	isInRole(role:string){
    let roleList = this.getItem(this.userRoles) || "";
		return roleList.includes(role);
	}

	logOut() {
		this.user = null;
		if (this.userSubject) this.userSubject.complete();
		this.userSubject = null;
		localStorage.clear();
		this.router.navigate(['login']);
	}

}
