import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from './shared/shared.module';
import { AuthService } from './modules/authentication/services/auth.service';
import { Interceptor } from './modules/authentication/services/interceptor.service';
import { DashboardGuardService } from './modules/dashboard/services/dashboard-guard.service';

import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import en from '@angular/common/locales/en';

registerLocaleData(en);

const ROUTES = RouterModule.forRoot([
	{ path: 'login', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule) },
	{ path: '', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [DashboardGuardService] },
	{ path: '**', pathMatch: 'full', redirectTo: 'login' }
]);

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		SharedModule,
		NgZorroAntdModule,
		BrowserModule,
		BrowserAnimationsModule,
		ROUTES
	],
	providers: [
		AuthService,
		{ provide: NZ_I18N, useValue: en_US },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Interceptor,
			multi: true
        }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
