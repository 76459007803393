import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthService } from '../../authentication/services/auth.service';

export const ACTIVA_NUMBER_FILE: string = 'ALL_ACTIVAS';

@Injectable({
	providedIn: 'root'
})
export class ActivaService {

	private lastFilters: any;
	private activaLastFilters: any;
	private activa: any;
	private hardware: any;
	private generalInfo: any;
	public hardwareSelectData: any;
	public activaSelectData: any;
	public lastHardwareList: any[] = [];
	public lastActivaList: any[] = [];

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {
		// Activas with general info
		// [create an array with all activas, without repeat]
		// forkJoin(["N125218", "B725647", "B725697", "B725732", "B728978", "B711308", "N157281", "B726378", "F1007177", "B728793", "B726194", "B726229", "N154838", "N155032", "N155236", "N155386", "N156013", "F1007023", "N157110", "N154158", "N156781", "N156782", "F1007038", "FGK0108", "FGK0101", "FGK0104", "N156768", "B726119", "B726113", "N153906", "B726264", "N155863", "N155578", "N155698", "N154316", "N154754", "N157341", "N155963", "B728152", "B728191", "B728653", "B729003", "B729028", "B729178", "B729268", "N153812", "F1007093", "F1007046", "F1007025", "F1007035"].map((activa: string) => {
		// 	return this.getGeneralInfo(activa);
		// })).subscribe((data: any[]) => {
		// 	let activas: string[] = [];
		// 	data.forEach((x => {
		// 		if (x.activaNumber) activas.push(x.activaNumber);
		// 	}));
		// 	console.log(activas);
		// });

	}

	

	isInRole(role:string):boolean{
		return this.authService.isInRole(role);
	}

	getFilters(activa: boolean = false) {
		if (activa) return this.activaLastFilters;
		return this.lastFilters;
	}

	getActiva() {
		return this.activa;
	}

	getHardware() {
		return this.hardware;
	}

	getGeneralInfoObject() {
		return this.generalInfo;
	}

	setFilters(lastFilters: any, activa: boolean = false) {
		if (activa) {
			this.activaLastFilters = lastFilters;
			return;
		}
		this.lastFilters = lastFilters;
	}

	setActiva(activa: any) {
		this.activa = activa;
	}

	setHardware(hardware: any) {
		this.hardware = hardware;
	}

	setGeneralInfo(generalInfo: any) {
		this.generalInfo = generalInfo;
	}

	cleanData() {
		this.lastFilters = null;
		this.activa = null;
		this.hardware = null;
		this.generalInfo = null;
	}

	// HTTP Requests

	getActivaSelectData() {
		return this.http.get(`${environment.URL_API}/activa/selectData`);
	}

	getHardwareSelectData() {
		return this.http.get(`${environment.URL_API}/hardware/selectData`);
	}

	getActivaSearch(filters: any) {
		return this.http.post(`${environment.URL_API}/activa/find`, filters);
	}

	getHardwareSearch(filters: any) {
		return this.http.post(`${environment.URL_API}/hardware/find`, filters);
	}

	getActivaList() {
		return this.http.get(`${environment.URL_API}/activa`);
	}

	getActivaByActivaNumber(activaNumber: string) {
		return this.http.get(`${environment.URL_API}/activa/byactiva/${activaNumber}`);
	}

	getHardwareList() {
		return this.http.get(`${environment.URL_API}/hardware`);
	}

	getHardwareByActivaNumber(activaNumber: string) {
		return this.http.get(`${environment.URL_API}/Hardware/byactiva/${activaNumber}`);
	}

	getGeneralInfo(activaNumber: string) {
		return this.http.get(`${environment.URL_API}/HardwareGeneralInfo/byactiva/${activaNumber}`);
	}

	setGeneralInformation(generalInfoId: any, generalInfo: any) {
		return this.http.put(`${environment.URL_API}/HardwareGeneralInfo/${generalInfoId}`, generalInfo);
	}

	updateGIM(activas: any[]) {
		return forkJoin(activas.map((activa: any) => {
			return this.http.put(`${environment.URL_API}/Activa/${activa.id}`, activa);
		}));
	}

	// Files

	getActivaFiles(activaNumber: string, typeNumber: string) {
		return this.http.get(`${environment.URL_API}/files/byactiva/${activaNumber}/${typeNumber}`);
	}

	getHardwareFiles(typeNumber: string) {
		return this.http.get(`${environment.URL_API}/files/bytypenumber/${typeNumber}`);
	}

	downloadActivaFile(fileId: number) {
		window.open(`${environment.URL_API}/files/${fileId}?Authorization=Bearer ${this.authService.getToken()}`, '_blank');
	}

	uploadActivaFile(formData: FormData) {
		return this.http.post(`${environment.URL_API}/files/hardware`, formData);
	}

	deleteFile(fileId: number) {
		return this.http.delete(`${environment.URL_API}/files/delete/${fileId}`);
	}

	getActivaLogo(typeNumber: string) {

		return this.http.get(`${environment.URL_API}/files/logo/${typeNumber}`);
	}

	uploadActivaLogo(formData: FormData) {
		formData.append("description", "HARDWARE_LOGO");
		return this.http.post(`${environment.URL_API}/files/hardware_assets`, formData);
	}

	// Activa template (Downoad, Modify, Upload and Update)

	downloadGeneralInfoTemplate() {
		this.getActivaFiles(ACTIVA_NUMBER_FILE, null).subscribe((files: any[]) => this.downloadActivaFile(files[0].id));
	}

	cleanActivaChanges() {
		return this.http.delete(`${environment.URL_API}/files/Hardware_General_Info`);
	}

	uploadActivaChanges(formData: FormData) {
		return this.http.post(`${environment.URL_API}/files/Hardware_General_Info`, formData);
	}

	updateActivaChanges() {
		return this.http.get(`${environment.URL_API}/HardwareGeneralInfo/updateRecords`);
	}

}